
import { Vue, Component } from 'vue-property-decorator'
import { PlanReportList } from '@/types/operationMonthReport'
import ImportData from './PlanImport.vue'
import { KeepAlive } from '@/utils/decorators'

@Component({
  name: 'PlanReport',
  components: { ImportData }
})
@KeepAlive
export default class PlanReport extends Vue {
  searchForm = {
    projectId: '',
    startDate: '',
    endDate: '',
    planType: '',
    status: ''
  }

  dateArr = []
  page = 1
  size = 10
  total = 0
  loading = false
  isShowImport = false
  dataList: PlanReportList[] = []
  pickMinTime: any = null
  typeList = [
    {
      name: '周工作计划',
      value: '1'
    },
    {
      name: '周工作汇报',
      value: '2'
    },
    {
      name: '月工作计划',
      value: '3'
    }
  ]

  pickerOptions: any = {
    // 限制最大6个月
    onPick: (date: {maxDate: string; minDate: any}) => {
      this.pickMinTime = date.minDate.getTime()
    },
    // 限制范围最大6个月
    disabledDate: (time: any) => {
      if (this.pickMinTime) {
        const one = 6 * 30 * 24 * 3600 * 1000
        const minTime: number = this.pickMinTime - one
        const maxTime: number = this.pickMinTime + one
        return (time.getTime() < minTime || time.getTime() > maxTime)
      }
    }
  }

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.loadData()
  }

  loadData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<PlanReportList> }>(this.$apis.operationmanage.selectPlanReportByPage, {
      ...this.searchForm,
      selectType: '0',
      page: this.page,
      size: this.size
    }).then(res => {
      this.dataList = res.list || []
      this.total = res.total || 0
    }).finally(() => {
      this.loading = false
    })
  }

  getStatus (status: string) {
    const arr = ['待提交', '已提交', '已审批', '已驳回']
    return arr[Number(status) - 1]
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  onImport () {
    this.isShowImport = true
  }

  dateChange (date: any) {
    if (date) {
      this.searchForm.startDate = date[0]
      this.searchForm.endDate = date[1]
    } else {
      this.searchForm.startDate = ''
      this.searchForm.endDate = ''
    }
  }

  addTypeData (row: any) {
    const nameArr = ['WeekPlanAdd', 'WeekBerichtAdd', 'MonthPlanAdd']
    this.$router.push({
      name: nameArr[Number(row.value) - 1],
      params: { type: row.value }
    })
  }

  toDetail (row: any) {
    this.$router.push({
      name: 'PlanReportDetail',
      params: { reportId: row.reportId }
    })
  }

  toUpdate (row: PlanReportList) {
    const nameArr = ['WeekPlanEdit', 'WeekBerichtEdit', 'MonthPlanEdit']
    this.$router.push({
      name: nameArr[Number(row.planType) - 1],
      params: { reportId: row.reportId, type: row.planType }
    })
  }

  toRetract (reportId: string) {
    this.$confirm('确认撤回该记录？', '提示').then(() => {
      this.$axios.post(this.$apis.operationmanage.updateStatusWithdraw, {
        reportId: reportId,
        withdraw: '1',
        status: '1'
      }).then(() => {
        this.$message.success('撤回成功')
        this.loadData()
      })
    })
  }

  toDelete (reportId: string) {
    this.$confirm('确认删除该记录？', '提示').then(() => {
      this.$axios.post(this.$apis.operationmanage.deletePlanReport, {
        reportId
      }).then(() => {
        this.$message.success('删除成功')
        this.loadData()
      })
    })
  }
}
